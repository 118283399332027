<template>
	<section
		:class="{
			'all-work-page_mobile': mobileMode,
			'all-work-page_desktop': !mobileMode,
		}"
		class="all-work-page"
	>
		<filter-panel v-if="mobileMode" />
		<grid-layout :tiles="tiles" />
	</section>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
// import { projects } from '@/api';

import FilterPanel from '@/components/all-work/filter-panel.vue';
import GridLayout from '@/components/grid-layout.vue';

const FILTER_CARD = Object.freeze({
	type: 'filter-card',
	data: {
		slug: 'FILTER-CARD',
		cell: { w: 1, h: 2 },
	},
});


export default {
	name: 'AllWork',


	data() {
		return {

		};
	},


	components: {
		GridLayout,
		FilterPanel,
	},


	computed: {
		...mapState('tags', ['defaultGroup']),
		...mapState('projects', { projects: 'list' }),
		...mapState('browser', { windowWidth: 'width', windowHeight: 'height' }),
		...mapState(['route']),
		...mapGetters('browser', ['mobileMode']),


		tag() {
			return this.route.query[this.defaultGroup] || null;
		},


		tiles() {
			let projects = this.projects.slice(0);

			if (this.tag) {
				projects = projects.filter(project => project.tags.indexOf(this.tag) !== -1);
			}

			const tiles = [];
			if (!this.mobileMode) {
				tiles.push(FILTER_CARD);
			}

			return [
				...tiles,
				...projects.map(project => ({
					type: 'project-card',
					data: project,
				})),
			];
		},
	},


	methods: {
		...mapActions('projects', ['getProjects']),
		...mapActions('tags', ['getTags']),
	},


	mounted() {
		if (this.projects.length === 0) {
			this.getProjects();
		}
		if (!this.tags || this.tags.length === 0) {
			this.getTags();
		}
	}
};
</script>

<style lang="sass">
// .all-work-page

.all-work-page_mobile
	padding: rslv($hp__height__mob) 0 0 0

.all-work-page_desktop
	padding: rslv($hp__height) 0 0 0
</style>
