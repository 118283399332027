<template>
	<div
		class="filter-panel"
	>
		<button
			type="button"
			class="filter-caption"
			v-html="filterTitle"
			@click="handleShowDropdownClick"
			:disabled="isDropdownVisible"
		><span v-html="filterTitle" /></button>


		<transition name="dropdown-fade">
			<div
				v-if="isDropdownVisible"
				class="dropdown"

				v-click-outside="{
					handler: handleClickOutside,
				}"
			>
				<template
					v-for="(option, index) in filterOptions"
				>
					<router-link
						:key="option.slug || 'all'"
						:class="[
							'filter-panel__tag',
							{ 'nobr': option.label.length <= 12 }
						]"

						:to="option.to"
						replace
						@click.native="handleClickOutside"
					><span v-html="option.label" /></router-link>&ZeroWidthSpace;
				</template>
			</div>
		</transition>

		<button
			type="button"
			:class="[
				'current-tag',
				{ 'current-tag_hide': isDropdownVisible }
			]"

			@click="handleShowDropdownClick"
		><span v-html="currentOptionLabel"/></button>
	</div>
</template>

<script>
import { cleanOfBlockTags } from '@/utilites.js';
import { mapState, mapGetters } from 'vuex';
import { ALL_PROJECTS_ROUTE_NAME } from '@/constants/router-strings.js';


export default {
	name: 'FilterPanel',

	data() {
		return {
			isDropdownVisible: false,
		};
	},

	computed: {
		...mapState('tags', ['tags', 'groups', 'defaultGroup']),
		...mapGetters('tags', ['getGroup', 'getTagsByGroup', 'allGroupsBySlug']),
		...mapState(['route']),


		filterOptions() {
			const tags = this.tags.filter(tag => tag.group === this.defaultGroup);

			return [
				{
					slug: null,
					label: this.$t('filter_all'),
					to: {
						name: ALL_PROJECTS_ROUTE_NAME,
						query: {},
					}
				},
				...tags.map(tag => ({
					slug: tag.slug,
					label: cleanOfBlockTags(tag.title),
					to: {
						name: ALL_PROJECTS_ROUTE_NAME,
						query: {
							[this.defaultGroup]: tag.slug,
						},
					},
				})),
			];
		},

		// filter slug
		filter() {
			let filterList = this.groups.map(g => g.slug);
			let filterFromQuery = Object.keys(this.route.query).filter(q => filterList.indexOf(q) !== -1);
			return filterFromQuery[0] || this.defaultGroup;
		},

		currentFilterGroup() {
			return this.getGroup(this.defaultGroup);
		},

		filterTitle() {
			if (this.currentFilterGroup) {
				return this.currentFilterGroup.title;
			} else {
				return this.$t('no_filters');
			}
		},

		currentTag() {
			if (this.filterOptions){
				let tag = this.filterOptions.find(t => t.slug === this.route.query[this.defaultGroup]);
				return tag || null;
			} else {
				return null;
			}
		},


		currentOptionLabel() {
			return this.currentTag
				? this.currentTag.label
				: this.$t('filter_all');
		},
	},

	methods: {
		handleShowDropdownClick() {
			if (!this.isDropdownVisible) this.isDropdownVisible = true;
		},

		handleClickOutside() {
			this.$nextTick(() => {
				this.isDropdownVisible = false;
			})
		},
	},
}
</script>

<style lang="sass" scoped>
%common-mobile-text
	font-family: $font__ibm
	font-size: rslv($fs__default-text__mob)
	line-height: $lh__default-text__mob

.filter-panel
	background-color: $clr__light-bg
	padding: rslv($pad__x__mob)
	position: relative
	z-index: 1

.filter-caption,
.current-tag
	@extend %clean-button
	@extend %common-mobile-text
	padding: 0
	margin: 0
	font-weight: normal
	cursor: pointer
	display: block
	text-align: left

.filter-caption
	color: $clr__subtitle

.current-tag_hide
	transition: opacity .2s ease
	opacity: 0
	pointer-events: none

.dropdown
	@extend %common-mobile-text
	position: absolute
	width: 100%
	box-sizing: border-box
	left: 0
	right: 0
	padding: 0 rslv($pad__x__mob) rslv($pad__x__mob)
	background-color: #ffffff
	top: calc(#{rslv(2 * $pad__x__mob)})
	background-color: $clr__light-bg


.filter-panel__tag
	color: inherit
	text-decoration: none
	color: $clr__def
	margin-right: 1.2em

	&.router-link-exact-active
		text-decoration: line-through



.dropdown-fade-enter,
.dropdown-fade-leave-to
	opacity: 0

.dropdown-fade-enter-active
	transition: opacity .3s ease-out

.dropdown-fade-leave-active
	transition: opacity .2s ease-out
</style>
