var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"filter-panel"},[_c('button',{staticClass:"filter-caption",attrs:{"type":"button","disabled":_vm.isDropdownVisible},domProps:{"innerHTML":_vm._s(_vm.filterTitle)},on:{"click":_vm.handleShowDropdownClick}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.filterTitle)}})]),_c('transition',{attrs:{"name":"dropdown-fade"}},[(_vm.isDropdownVisible)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:({
				handler: _vm.handleClickOutside,
			}),expression:"{\n\t\t\t\thandler: handleClickOutside,\n\t\t\t}"}],staticClass:"dropdown"},[_vm._l((_vm.filterOptions),function(option,index){return [_c('router-link',{key:option.slug || 'all',class:[
						'filter-panel__tag',
						{ 'nobr': option.label.length <= 12 }
					],attrs:{"to":option.to,"replace":""},nativeOn:{"click":function($event){return _vm.handleClickOutside($event)}}},[_c('span',{domProps:{"innerHTML":_vm._s(option.label)}})]),_vm._v("​ ")]})],2):_vm._e()]),_c('button',{class:[
			'current-tag',
			{ 'current-tag_hide': _vm.isDropdownVisible }
		],attrs:{"type":"button"},on:{"click":_vm.handleShowDropdownClick}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.currentOptionLabel)}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }